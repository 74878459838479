import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import loan from './loan';
import settings from './settings';
import uiblock from './ui-block';
import support from './support';
import general from './general';
import lender from './lender';

const reducers = combineReducers({
    uiblock,
    form: formReducer,
    routing: routerReducer,
    user,
    loan,
    settings,
    support,
    general,
    lender,
});

export default reducers;
