import * as types from './types';

export const setMyAmount = amount => {
	return {
		type: types.SET_DESIRED_AMOUNT,
		payload: amount,
	};
};

export const setMyMonthlyDeduction = amount => {
	return {
		type: types.SET_DESIRED_MONTHLY_REPAYMENT,
		payload: amount,
	};
};

export const setMonthlyRepayment = amount => {
	return {
		type: types.SET_MONTHLY_REPAYMENT,
		payload: amount,
	};
};

export const setNetEarning = amount => {
	return {
		type: types.SET_NET_EARNING,
		payload: amount,
	};
};

export const setEligibleAmount = amount => {
	return {
		type: types.SET_ELIGIBLE_AMT,
		payload: amount,
	};
};

export const setTenure = tenure => {
	return {
		type: types.SET_TENURE,
		payload: tenure,
	};
};

export const setRemitaID = id => {
	return {
		type: types.SET_REMITA_ID,
		payload: id,
	};
};

export const calculateSMELoan = eligible_amt => {
	return dispatch => {
		dispatch(setNetEarning(eligible_amt));
		dispatch(setMonthlyRepayment(0)); 
		dispatch(setEligibleAmount(eligible_amt));
	};
};

export const calculateLoan = (net_earning, tenure, interest_rate, percent) => {
	return dispatch => {
		const monthly_repayment = (net_earning * percent).toFixed(2);
		const eligible_amt = (monthly_repayment / (1 / tenure + interest_rate / 100)).toFixed(2);

		dispatch(setNetEarning(net_earning));
		dispatch(setMonthlyRepayment(monthly_repayment));
		dispatch(setEligibleAmount(eligible_amt));
	};
};

export const calculateNewLoan = (amount, tenure, interest_rate, lender) => {
	return dispatch => {
		const repayment = (amount * (1 / tenure + interest_rate / 100)).toFixed(2);
		
		dispatch(setMyAmount(amount));
		dispatch(setMyMonthlyDeduction(repayment));
		
		const monthly_principal = (amount / tenure).toFixed(2);
		const monthly_interest = (repayment - monthly_principal).toFixed(2);
		
		dispatch({ type: types.SET_MONTHLY_PRINCIPAL, payload: monthly_principal });
		dispatch({ type: types.SET_MONTHLY_INTEREST, payload: monthly_interest });
		
		let monthly_comm = 0;
		
		dispatch({ type: types.SET_MONTHLY_COMMISSION, payload: monthly_comm });
	};
};

export const setCurrentLoan = details => {
	return {
		type: types.SET_CURRENT_LOAN,
		payload: details,
	};
};

export const setDisburseLoan = amount => {
	return {
		type: types.SET_DISBURSE_AMOUNT,
		payload: parseFloat(amount),
	};
};

export const updateCurrentLoan = details => {
	return {
		type: types.UPDATE_CURRENT_LOAN,
		payload: details,
	};
};

export const setLoans = details => {
	return {
		type: types.SET_LOANS,
		payload: details,
	};
};

export const setEarnings = details => {
	return {
		type: types.SET_LOAN_EARNINGS,
		payload: details,
	};
};

export const addLoan = loan => {
	return {
		type: types.ADD_LOAN,
		payload: loan,
	};
};

export const resetLoanData = () => {
	return {
		type: types.RESET_LOAN_DATA,
	};
};

export const doRefresh = status => {
	return {
		type: types.REFRESH_LOAN,
		payload: status,
	};
};
