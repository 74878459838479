import * as types from './types';
import { settingKey } from '../config/constants';
import { setTenure } from './loan';

export const putSettings = settings => {
	return dispatch => {
		localStorage.setItem(settingKey, JSON.stringify(settings));
		dispatch({
			type: types.PUT_SETTINGS,
			payload: settings,
		});
		const item = settings.find(s => s.name === 'max_loan_tenure');
		const maxLoanTenure = item ? parseInt(item.value, 10) : 1;
		dispatch(setTenure(maxLoanTenure));
	};
};

export const updateWallet = wallet => {
	console.log(wallet);
	return;
};
