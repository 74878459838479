// users
export const SIGN_OUT = 'SIGN_OUT';
export const START_PRELOADING = 'START_PRELOADING';
export const STOP_PRELOADING = 'STOP_PRELOADING';
export const LOAD_USERS = 'LOAD_USERS';
export const CHANGE_USER_DATA = 'CHANGE_USER_DATA';
export const SET_PAYSLIP_INFO = 'SET_PAYSLIP_INFO';
export const TOGGLE_RPT_MENU = 'TOGGLE_RPT_MENU';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_EARNINGS = 'SET_EARNINGS';
export const ADD_ACCOUNTS = 'ADD_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const SET_IPPIS = 'SET_IPPIS';
export const SHOW_PROFILE = 'SHOW_PROFILE';
export const CLOSE_PROFILE = 'CLOSE_PROFILE';
export const SET_PHONE = 'SET_PHONE';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_REMITA_INFO = 'SET_REMITA_INFO';

// general
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const LOAD_BANKS = 'LOAD_BANKS';

// ui-block
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

// settings
export const PUT_SETTINGS = 'PUT_SETTINGS';
export const UPDATE_WALLET_AMOUNT = 'UPDATE_WALLET_AMOUNT';

// loan
export const SET_DESIRED_AMOUNT = 'SET_DESIRED_AMOUNT';
export const SET_DESIRED_MONTHLY_REPAYMENT = 'SET_DESIRED_MONTHLY_REPAYMENT';
export const SET_MONTHLY_REPAYMENT = 'SET_MONTHLY_REPAYMENT';
export const SET_NET_EARNING = 'SET_NET_EARNING';
export const SET_ELIGIBLE_AMT = 'SET_ELIGIBLE_AMT';
export const SET_TENURE = 'SET_TENURE';
export const SET_CURRENT_LOAN = 'SET_CURRENT_LOAN';
export const SET_LOANS = 'SET_LOANS';
export const RESET_LOAN_DATA = 'RESET_LOAN_DATA';
export const UPDATE_CURRENT_LOAN = 'UPDATE_CURRENT_LOAN';
export const ADD_LOAN = 'ADD_LOAN';
export const SET_MONTHLY_PRINCIPAL = 'SET_MONTHLY_PRINCIPAL';
export const SET_MONTHLY_INTEREST = 'SET_MONTHLY_INTEREST';
export const SET_LOAN_EARNINGS = 'SET_LOAN_EARNINGS';
export const REFRESH_LOAN = 'REFRESH_LOAN';
export const UPDATE_USER_LOAN_DATA = 'UPDATE_USER_LOAN_DATA';
export const SET_DISBURSE_AMOUNT = 'SET_DISBURSE_AMOUNT';
export const SET_MONTHLY_COMMISSION = 'SET_MONTHLY_COMMISSION';
export const SET_REMITA_ID = 'SET_REMITA_ID';

// support
export const LOAD_SUPPORT = 'LOAD_SUPPORT';
export const NEW_SUPPORT = 'NEW_SUPPORT';
export const UPDATE_SUPPORT = 'UPDATE_SUPPORT';
export const TOGGLE_UPDATED = 'TOGGLE_UPDATED';

// lender
export const LOAD_LENDERS = 'LOAD_LENDERS';
export const SET_LENDER_PROFILE = 'SET_LENDER_PROFILE';
