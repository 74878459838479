/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import 'intro.js/introjs.css';
import { Hints } from 'intro.js-react';

let hintsEnabled = true;
let hints = [
	{ element: ".hint-icon", hint: "You have a support reply", hintPosition: "middle-right" }
]
const LiLink = ({ to, label, exact, onClick, icon, data }) => (
    <Route path={to} exact={exact} children={({ match }) => (
        <li style={{ position: 'relative' }} className={match ? 'active' : ''}>
            <Link onClick={onClick} to={to} className={icon ? 'relative' : ''}>{label}</Link>
			{/* {icon == true && 
			<span className='support-icon'>{data}</span>
			} */}
			{icon === true && 
			<span className='hint-icon'>
				{data > 0 && <Hints enabled={hintsEnabled} hints={hints} /> }
			</span>
			}
        </li>
    )}/>
);

const MenuLinks = ({ location, showSubMenu, toggleMenu, toggleMenuOpen, activateTopUpLink, user, loan }) => {
	useEffect(() => {
		let hintElem = document.querySelector(".introjs-hint-pulse");
		if(hintElem){
			hintElem.innerHTML = "<span style='font-size: 9px; background: rgba(255, 90, 90, 0.7); font-weight: 600; color: #fff; position: absolute;'>"+user.pending_support+"</span>"
		}
	}, [user]);

	return (
		<div className="top-menu-secondary desktop">
			<ul>
				<LiLink onClick={toggleMenu} to="/dashboard" label='DASHBOARD'/>
				<li className={`has-sub-menu ${location.pathname === '/loan-request' || location.pathname === '/loan-history' || location.pathname === '/loan-topup' ? 'active' : ''} ${showSubMenu ? 'selected' : ''}`}>
					<a role="button" tabIndex="0" onClick={toggleMenuOpen}>LOANS</a>
					<div className="sub-menu-w">
						<div className="sub-menu-i">
							<ul className="sub-menu">
								<li>
									<Link onClick={toggleMenu} to="/loan-request">NEW LOAN REQUEST</Link>
								</li>
								{activateTopUpLink && (
									<li>
										<Link onClick={toggleMenu} to="/loan-topup">LOAN TOP-UP</Link>
									</li>
								)}
								<li>
									<Link onClick={toggleMenu} to="/loan-history">LOAN HISTORY</Link>
								</li>
							</ul>
						</div>
					</div>
				</li>
				{/* <LiLink to="/investment" label='INVESTMENT'/> */}
				{/* {loan && <LiLink onClick={toggleMenu} to="/liquidate" label='LIQUIDATE'/>} */}
				<LiLink onClick={toggleMenu} to="/wallet" label='WALLET'/>
				<LiLink onClick={toggleMenu} to="/support" label='SUPPORT' data={user.pending_support} icon={true}/>
				{user.role === 'u-merchant' && user.merchant_id && (
					<LiLink onClick={toggleMenu} to={`/merchant/${user.merchant_id}`} label='MERCHANT'/>
				)}
			</ul>
			<div className="top-menu-controls">
				<Link onClick={toggleMenu} to='/logout' className="top-icon top-settings"><i className="os-icon os-icon-signs-11"/></Link>
			</div>
		</div>
	);
};

export default MenuLinks;