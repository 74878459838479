import * as types from './types';

export const doNotify = (data) => {
	return {
		type: types.SET_NOTIFICATION,
		payload: data,
	};
};

export const loadBanks = (data) => {
	return {
		type: types.LOAD_BANKS,
		payload: data,
	};
};
