import * as types from '../actions/types';

const uiblock = (state = [], action) => {
	switch (action.type) {
		case types.REQUEST_START:
		case types.REQUEST_SUCCESS:
		default:
			return [...state, action.type];
	}
};

export default uiblock;
