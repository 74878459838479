import * as types from '../actions/types';

const INITIAL_STATE = {
	myAmount: 0,
	myDisburseAmount: 0,
	myMonthlyDeductions: 0,
	monthlyDeductions: 0,
	monthlyCommission: 0,
	netEarning: 0,
	eligibleAmt: 0,
	monthlyPrincipal: 0,
	monthlyInterest: 0,
	tenure: 1,
	current: null, // user's current loan for user
	earnings: [],
	refresh: false,
	loans: [],
	remita_id: null,
};

const loan = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.SET_DESIRED_AMOUNT:
			return { ...state, myAmount: action.payload };
		case types.SET_DISBURSE_AMOUNT:
			return { ...state, myDisburseAmount: action.payload };
		case types.SET_DESIRED_MONTHLY_REPAYMENT:
			return { ...state, myMonthlyDeductions: action.payload };
		case types.SET_MONTHLY_REPAYMENT:
			return { ...state, monthlyDeductions: action.payload };
		case types.SET_NET_EARNING:
			return { ...state, netEarning: action.payload };
		case types.SET_ELIGIBLE_AMT:
			return { ...state, eligibleAmt: action.payload };
		case types.SET_TENURE:
			return { ...state, tenure: action.payload };
		case types.SET_CURRENT_LOAN:
			return { ...state, current: action.payload };
		case types.UPDATE_CURRENT_LOAN:
			const loan = {
				liquidated: action.payload.liquidated,
				liquidated_at: action.payload.liquidated_at,
				liquidate_approve: action.payload.liquidate_approve,
				cancel_request: action.payload.cancel_request,
				cancel_reason: action.payload.cancel_reason,
			};
			return { ...state, current: { ...state.current, ...loan } };
		case types.RESET_LOAN_DATA:
			return {
				...state,
				myAmount: 0,
				myMonthlyDeductions: 0,
				monthlyDeductions: 0,
				netEarning: 0,
				eligibleAmt: 0,
				tenure: 12,
				myDisburseAmount: 0,
				monthlyCommission: 0,
			};
		case types.SET_MONTHLY_PRINCIPAL:
			return { ...state, monthlyPrincipal: action.payload };
		case types.SET_MONTHLY_INTEREST:
			return { ...state, monthlyInterest: action.payload };
		case types.SET_MONTHLY_COMMISSION:
			return { ...state, monthlyCommission: action.payload };
		case types.SET_LOAN_EARNINGS:
			return { ...state, earnings: action.payload };
		case types.REFRESH_LOAN:
			return { ...state, refresh: action.payload };
		case types.SET_LOANS:
			return { ...state, loans: [...action.payload] };
		case types.ADD_LOAN:
			return { ...state, loans: [...state.loans, action.payload] };
		case types.SET_REMITA_ID:
			return { ...state, remita_id: action.payload };
		default:
			return state;
	}
};

export default loan;
