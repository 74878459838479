import * as types from './types';

export const loadLenders = data => {
	return {
		type: types.LOAD_LENDERS,
		payload: data,
	};
};

export const setLenderProfile = data => {
	return {
		type: types.SET_LENDER_PROFILE,
		payload: data,
	};
};
