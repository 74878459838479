import * as types from '../actions/types';

const INITIAL_STATE = {
	profile: null,
	list: [],
};

const lender = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.LOAD_LENDERS:
			return { ...state, list: [...action.payload] };
		case types.SET_LENDER_PROFILE:
			return { ...state, profile: action.payload };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE };
		default:
			return state;
	}
};

export default lender;
