/* eslint eqeqeq: 0 */
import * as types from '../actions/types';

const INITIAL_STATE = {
	user: null,
	bank_accounts: [],
	loggedIn: false,
	preloading: true,
	verified: false,
	payslip: null,
	earnings: [],
	message: '',
	show_report_menu: false,
	report: '',
	ippis: '',
	user_id: '',
	user_email: '',
	show_profile: false,
	phone: '',
	remitaDatum: null,
};

const user = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.START_PRELOADING:
			return { ...state, preloading: true };
		case types.STOP_PRELOADING:
			return { ...state, preloading: false };
		case types.CHANGE_USER_DATA:
			return { ...state, user: action.user, loggedIn: action.status };
		case types.UPDATE_USER:
			return { ...state, user: { ...state.user, ...action.payload } };
		case types.UPDATE_USER_LOAN_DATA:
			return { ...state, user: { ...state.user, loan_id: null } };
		case types.SIGN_OUT:
			return { ...state, ...INITIAL_STATE, preloading: false };
		case types.SET_PAYSLIP_INFO:
			return { ...state, payslip: action.payload };
		case types.SET_REMITA_INFO:
			return { ...state, remitaDatum: action.payload };
		case types.SET_EARNINGS:
			return { ...state, earnings: action.data };
		case types.TOGGLE_RPT_MENU:
			return { ...state, show_report_menu: action.payload };
		case types.ADD_ACCOUNTS:
			return { ...state, bank_accounts: action.payload };
		case types.ADD_ACCOUNT:
			return {
				...state,
				bank_accounts: [...state.bank_accounts, action.payload],
			};
		case types.SET_IPPIS:
			return { ...state, ippis: action.payload };
		case types.SET_PHONE:
			return { ...state, phone: action.payload };
		case types.SHOW_PROFILE:
			return {
				...state,
				show_profile: action.data.show_profile,
				user_id: action.data.user_id,
			};
		case types.RESEND_EMAIL:
			return {
				...state,
				user_id: action.payload.user_id,
				user_email: action.payload.user_email,
			};
		case types.CLOSE_PROFILE:
			return { ...state, show_profile: false, user_id: '' };
		case types.SET_LENDER_PROFILE:
			return { ...state, lender: action.payload };
		case types.UPDATE_WALLET:
			return {
				...state,
				user: {
					...state.user,
					wallet: action.payload.wallet,
					has_request: action.payload.has_request,
				},
			};
		default:
			return state;
	}
};

export default user;
