import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Link, Switch, withRouter } from 'react-router-dom';
import ReduxBlockUi from 'react-block-ui/redux';
import Loadable from 'react-loadable';
import moment from 'moment';
import NotificationSystem from 'react-notification-system';
import $ from 'jquery';

import logo from './assets/img/fc-logo.png';
import { doNotify } from './actions/general';
import loader from './assets/img/loader.gif';
import Loading from './components/Loading';
import ScrollToTop from './container/ScrollToTop';
import MenuLinks from './components/MenuLinks';

const Authenticate = Loadable({ loader: () => import('./pages/Authenticate'), loading: Loading });
const Setup = Loadable({ loader: () => import('./pages/Setup'), loading: Loading });
const Dashboard = Loadable({ loader: () => import('./pages/Dashboard'), loading: Loading });
const NoMatch = Loadable({ loader: () => import('./pages/NoMatch'), loading: Loading });
const Logout = Loadable({ loader: () => import('./pages/Logout'), loading: Loading });
// const Liquidate = Loadable({ loader: () => import('./pages/Liquidate'), loading: Loading });
const LoanRequest = Loadable({ loader: () => import('./pages/LoanRequest'), loading: Loading });
const LoanTopup = Loadable({ loader: () => import('./pages/LoanTopup'), loading: Loading });
const LoanHistory = Loadable({ loader: () => import('./pages/LoanHistory'), loading: Loading });
const Support = Loadable({ loader: () => import('./pages/Support'), loading: Loading });
const UserAgreement = Loadable({ loader: () => import('./pages/UserAgreement'), loading: Loading });
const PrivacyPolicy = Loadable({ loader: () => import('./pages/PrivacyPolicy'), loading: Loading });
const ResetPassword = Loadable({ loader: () => import('./pages/ResetPassword'), loading: Loading });
const Merchant = Loadable({ loader: () => import('./pages/Merchant'), loading: Loading });
const Wallet = Loadable({ loader: () => import('./pages/Wallet'), loading: Loading });
// const Investment = Loadable({ loader: () => import('./pages/Investment'), loading: Loading });
const EditProfile = Loadable({ loader: () => import('./pages/EditProfile'), loading: Loading });
const ConsentLoan = Loadable({ loader: () => import('./pages/ConsentLoan'), loading: Loading });

const LiLink = ({ to, label, exact, icon, data }) => (
    <Route path={to} exact={exact} children={({ match }) => (
        <li className={match ? 'active' : ''}>
            <Link to={to} style={{ position: 'relative' }}>
                <span>{label}</span>
                {icon === true && <span className='support-mobile-icon'>{data}</span> }
            </Link>
            
        </li>
    )}/>
);

class App extends Component {
	constructor(props) {
        super(props);
		this.state = {
            notified: false,
            showSubMenu: false,
		};
        this.notifRef = null;
	}
    
    toggleMobileMenu = (e) => {
        e.preventDefault();
        $(this.refs.mmenu).toggleClass('show-mobile');
    };

    componentWillUpdate(nextProps, nextState) {
		if (!nextState.notified && nextProps.notifdata) {
			const { title, message, level } = nextProps.notifdata;
			const id = moment().format('X');
			this.notify(title, message, level, id);
            this.setState({ notified: true });
			this.props.doNotify(null);
		}
	}

	notify = (title, message, level, id) => {
		this.refs.notifRef.addNotification({
			title,
			message,
			level,
			autoDismiss: 2,
			uid: id,
			onRemove: this.onRemove,
		});
    };

    toggleMenu = () => this.setState({ showSubMenu: false });

    toggleMenuOpen = () => this.setState({ showSubMenu: !this.state.showSubMenu });
    
    onRemove = () => this.setState({ notified: false });

	render() {
        const { preloading, loggedIn, loan, user, location } = this.props;
        const { showSubMenu } = this.state;

        const activateTopUpLink = loan && loan.tenure >= 2;
        
		return preloading ? (
			<div className="loading-page">
				<img src={loader} alt="" />
			</div>
		) : (
			<ScrollToTop>
                <NotificationSystem ref="notifRef" />
				<ReduxBlockUi block="REQUEST_START" unblock={["REQUEST_SUCCESS", /fail/i]}>
					{loggedIn ? (
                        <div className="all-wrapper menu-side">
                            <div className="layout-w">
                                {location.pathname.split('/').pop() !== 'consent-loan' && <div className="menu-mobile menu-activated-on-click color-scheme-dark">
                                    <div className="mm-logo-buttons-w">
                                        <Link className="mm-logo" to="/dashboard">
                                            <img alt="logo" src={logo}/><span>FastCash</span>
                                        </Link>
                                        <div className="mm-buttons" onClick={this.toggleMobileMenu}>
                                            <div className="mobile-menu-trigger">
                                                <div className="os-icon os-icon-hamburger-menu-1"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="menu-and-user" ref='mmenu'>
                                        <ul className="main-menu">
                                            <LiLink to="/dashboard" label='DASHBOARD'/>
                                            <LiLink to={`/edit-profile/${user.id}`} label='EDIT PROFILE'/>
                                            <LiLink to="/loan-request" label='NEW LOAN REQUEST'/>
                                            {activateTopUpLink && <LiLink to="/loan-topup" label='LOAN TOP-UP'/>}
                                            <LiLink to="/loan-history" label='LOAN HISTORY'/>
                                            {/* <LiLink to="/investment" label='INVESTMENT'/> */}
                                            {/* {loan && <LiLink to="/liquidate" label='LIQUIDATE'/>} */}
                                            <LiLink to="/wallet" label='WALLET'/>
                                            <LiLink to="/support" label='SUPPORT' data={user.pending_support} icon={true}/>
                                            {user.role === 'u-merchant' && user.merchant_id && (
                                                <LiLink to={`/merchant/${user.merchant_id}`} label='MERCHANT'/>
                                            )}
                                            <LiLink to="/logout" label='LOGOUT'/>
                                        </ul>
                                    </div>
                                </div>}
                                <div className="content-w">
                                    {location.pathname.split('/').pop() !== 'consent-loan' && <MenuLinks
                                        location={location}
                                        showSubMenu={showSubMenu}
                                        activateTopUpLink={activateTopUpLink}
                                        user={user}
                                        loan={loan}
                                        toggleMenuOpen={this.toggleMenuOpen}
                                        toggleMenu={this.toggleMenu}
                                    />}
                                    <Switch>
                                        <Route path="/dashboard" component={Dashboard} />
                                        <Route path="/consent-loan" component={ConsentLoan} />
                                        <Route path="/loan-request" component={LoanRequest} />
                                        <Route path="/loan-topup" component={LoanTopup} />
                                        <Route path="/loan-history" component={LoanHistory} />
                                        {/* <Route path="/liquidate" component={Liquidate} /> */}
                                        <Route path="/support" component={Support} />
                                        <Route path="/logout" component={Logout} />
                                        <Route path="/user-agreement" component={UserAgreement} />
                                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                                        <Route path="/wallet" component={Wallet} />
                                        {/* <Route path="/investment" component={Investment} /> */}
                                        <Route path="/edit-profile/:id" component={EditProfile} />
							            <Route path="/merchant/:id" component={Merchant} />
                                        <Route component={NoMatch} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
					) : (
						<Switch>
							<Route exact path="/" component={Authenticate} />
                            <Route path="/consent-loan" component={ConsentLoan} />
							<Route path="/setup" component={Setup} />
							<Route path="/user-agreement" component={UserAgreement} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route path="/forgot-password" component={Authenticate} />
							<Route path="/reset-password" component={ResetPassword} />
							<Route path="/change-email" component={Authenticate} />
							<Route component={NoMatch} />
						</Switch>
					)}
				</ReduxBlockUi>
			</ScrollToTop>
		);
	}
}

const mapStateToProps = state => {
	return {
		preloading: state.user.preloading,
		loggedIn: state.user.loggedIn,
        loan: state.loan.current,
        notifdata: state.general.notifdata,
        user: state.user.user,
	};
};

export default withRouter(connect(mapStateToProps, { doNotify })(App));
