import * as types from '../actions/types';

const INITIAL_STATE = {
	notifdata: null,
	banks: [],
};

const general = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.SET_NOTIFICATION:
			return { ...state, notifdata: action.payload };
		case types.LOAD_BANKS:
			return { ...state, banks: action.payload };
		default:
			return state;
	}
};

export default general;
